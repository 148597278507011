/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { sessionStorage } from './sessionStorage';

/** This wrapper for sessionStorage.setItem() should only be called on shelf pages (brand or product) or search.
 *
 * @param xFusionQueryId the current session's xFusionQueryId
 * @returns true if xFusionQueryId was stored, it should never return false
 */
export function persistXFusionQueryId(xFusionQueryId?: string) {
  if (xFusionQueryId) {
    sessionStorage.setItem('xFusionQueryId', xFusionQueryId);
    return true;
  }
  return false;
}

/**
 * @returns sessionStorage.getItem('xFusionQueryId')
 */
export function getXFusionQueryId() {
  return sessionStorage.getItem('xFusionQueryId');
}
